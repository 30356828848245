import React from "react"
import SEO from "../../components/seo"
import HeroFeaturePage from "../../components/pages/shared/hero/feature-page"
import Layout from "../../components/layout"
import AboutComponent from "../../components/pages/main/about"
import FeaturesComponent from "../../components/pages/main/landing-features"
import ContactFormComponent from "../../components/pages/shared/contact-form"
import styles from "./feature.module.scss"
import FeaturePriceCards from "../../components/pages/features/feature-price"
import FeatureAccordion from "../../components/pages/main/accordion"
import Discount from "../../components/pages/main/discount"
import BannerComponent from "../../components/pages/features/banner"
import Breadcrumb from '../../components/controls/breadcrumbs';

const widgetsAccordionData = [
  {
    name: "onlineschedule",
    title: "Что такое онлайн-расписание в Параплан CRM?",
    content: `
      <p>Виджет онлайн-расписания - это ссылка, которая генерируется CRM-системой и является интерактивной копией раздела “Расписание” Параплана.</p>
      <p>Виджет содержит:</p>
      <ol>
        <li>наименование занятия</li>
        <li>дату проведения занятия</li>
        <li>день недели</li>
        <li>время проведения занятия</li>
        <li>количество доступных мест для записи</li>
        <li>помещение</li>
      </ol>  
      <p>Онлайн-расписание работает на любых устройствах. То есть ваши клиенты смогут записываться на занятия через браузер компьютера или смартфона, планшета. Расписание в виджете можно смотреть на текущую неделю, а также на предыдущие и будущие. Каждое занятие выделено своим цветом, чтобы клиенту было проще разобраться. Кроме этого, вы можете выставить ограничение для записи на конкретное занятие, и когда свободные места закончатся, записаться автоматически станет невозможно. Например, если вам необходимо записать клиентов на день открытых дверей или мастер-класс, добавьте событие как занятие в расписание и собирайте записи на ивент через виджет, а не вручную.</p>
    `,
  },
  {
    name: "widgetslink",
    title: "Где можно разместить ссылку на виджет онлайн-расписания для записи клиентов?",
    content: `
      <p>Рекомендуем по-максимуму использовать онлайн-расписание, чтобы не записывать клиентов вручную. Чем в большем количестве источников вы разместите ссылку, тем более организованным будет процесс записи. Также виджет онлайн-записи клиентов может служить дополнительным инструментом рекламы занятий и услуг детского центра, ведь просматривая онлайн-расписание, клиенты могут заинтересоваться чем-то помимо привычных курсов.</p>
      <p>Дополнительно используйте ссылку на виджет онлайн-расписания:</p>
      <ol>
        <li>на сайте или лендинге,</li>
        <li>в шапке профиля социальной сети,</li>
        <li>в постах Вконтакте или описании группы,</li>
        <li>в публикациях в Телеграм-канале (или закрепите ссылку вверху),</li>
        <li>в рекламных постах в таргетированной рекламе.</li>
      </ol>
      <p>Обязательно отправляйте ссылку на виджет в родительский чат при необходимости записи на занятия или мероприятие. Ссылка создается автоматически во вкладке “Интеграции” раздела “Настройки” в системе.</p>

    `,
  },
  {
    name: "record",
    title: 'Как клиенту записаться на занятие через виджет онлайн-записи?',
    content: `
    <p>Если у клиента уже есть ссылка на онлайн-расписание детского или учебного центра, то ему нужно перейти по ней и выбрать нужное занятие или услугу. У каждого занятия есть кнопка “Записаться”. При клике на кнопку появляется форма сбора заявок.</p>
    <p>Клиент должен заполнить:</p>
    <ol>
      <li>Имя</li>
      <li>Телефон</li>
      <li>Комментарий (при необходимости)</li>
    </ol>
    <p>Если у занятия не стоит ограничение на количество свободных мест для записи или доступные места еще есть, то запись пройдет успешно. Пользователь Параплан CRM увидит новую запись в разделах “Записи” и “Воронка продаж”. В первом разделе появится карточка конкретной записи, в который вы увидите информацию о ней, а также можете Подтвердить или Отменить запись. В воронке продаж новая запись попадет в столбец “Неразобранное” как новый лид.</p>
    <p>Также все владельцы аккаунта Параплана получает email-уведомления о новой записи на занятие через виджет (при включенных email-уведомлениях). Процесс онлайн-записи клиентов становится контролируемым.</p>
    `,
  },
]

const WidgetsFeaturePage = (props) => {
  const crumbs = [
    { name: 'Главная', url: '/'},
    { name: 'Возможности', url: '/feature'},
    { name: 'Виджеты', url: ''}
  ];
        return (
          <Layout className={styles.clients} headerColor={"#03C4A4"} page={"widgets"}>
                  <SEO
                    title="CRM-формы и виджеты онлайн расписания в CRM Параплан"
                    description="Интегрируйте свой сайт или социальные сети с CRM-формами или виджетом онлайн-расписания. Экспортируйте все заявки в CRM-систему."
                    url={"https://paraplancrm.ru" + props.location.pathname}
                  />
                  <HeroFeaturePage
                    name={"widgets"}
                    title='Получайте заявки сразу в CRM'
                    description='Автоматизируйте сбор лидов и запись на услуги, сеансы, занятия с любого источника.'
                    color='#03C4A4'
                    btnDark={true}
                    image={require("../../images/cover/widgets.png")} />
                  <Breadcrumb crumbs={ crumbs } arrowcolor={"green"}/>
                  <AboutComponent
                    title='Форма сбора заявок'
                    description='Привлекайте новые лиды через виджет на сайте и в социальных сетях. Все заявки перед глазами в воронке продаж.'
                    image={require("../../images/screenshot/widgets/widgets-1.png")}
                    imageWidth={676}
                    imageHeight={364}
                    bgSize={"position-bottom"}
                    reverse={true}
                    color='#03C4A4' />
                  <Discount className={styles.widgetsDiscount}  />
                  <AboutComponent
                    title='Онлайн-расписание с формой записи'
                    description='Настраиваемый виджет для онлайн записи клиентов. Форма для сайта и социальных сетей соберет записи на занятия и услуги, а вы сконцентрируйтесь на главном - продаже абонементов и поддержке клиентов.'
                    image={require("../../images/screenshot/widgets/online.png")}
                    imageWidth={676}
                    imageHeight={364}
                    bgSize={"position-top"}
                    color='#FFC800' />
                  <BannerComponent color={"green"} author={"Игорь Софронюк, школа танцев «The NINE»"}>
                          Лиды с формы записи автоматом добавляются в «Воронку продаж». С заявками очень удобно работать и доводить их до результата. Что особенно важно - все корректно работает на мобильной версии!
                  </BannerComponent>
                  <AboutComponent
                    title='Виджет для Tilda'
                    description='Направьте поток лидов с вашего сайта на конструкторе Tilda в воронку продаж Параплан. Встройте Webhook на сайт, и он точно определит источник заявки и автоматически занесет ее в аналитику.'
                    image={require("../../images/screenshot/widgets/webhook.png")}
                    imageWidth={676}
                    imageHeight={364}
                    reverse={true}
                    bgSize={"position-bottom"}
                    color='#03C4A4' />
                  <FeaturePriceCards
                    pageName="widgets"
                    title="Используйте виджеты для перевыполнения <br/> плана продаж!"
                    text="Стоимость интеграции с виджетами"
                  />
                  <FeaturesComponent title="Дополнительные возможности" />
                  <ContactFormComponent />
                  <FeatureAccordion
                    data={widgetsAccordionData}
                    title='Онлайн запись клиентов в детский центр с помощью виджета онлайн-расписания'
                    descr='CRM-система для детских и учебных центров Параплан предлагает рабочий инструмент для онлайн записи клиентов - виджет онлайн-расписания для сайта, лендинга и социальных сетей. Ваши клиенты смогут записываться на занятия или услуги в 2 клика в любое время суток. CRM-форма онлайн-записи помогает избежать накладок в расписании, перегрузки звонками и сообщениями администраторов или менеджеров по продажам. Унифицируйте процесс онлайн записи клиентов на занятия, сделайте его легким и прозрачным. Для этого вам нужно только предлагать клиентам записываться через онлайн-расписание от CRM-системы Параплан. Попробуйте виджет онлайн-расписания в работе вашего центра. Собирайте больше заявок и перевыполняйте планы продаж!”.'
                  />
          </Layout>
        )
}

export default WidgetsFeaturePage
